import React from 'react';
import { observer, inject } from 'mobx-react';
// import ReactDOM from 'react-dom';
import style from './style.module.css';

import ShopMenu from './ShopMenu';
import History from './History';
import MyBudget from './MyBudget';
import About from './About';
//import Terms from './Terms';
//import PPolicy from './PrivacyPolicy';
//import RetPolicy from './ReturnPolicy';
import Registration from './Registration';
import Contacts from './Contacts';
import Payment from './Payment';

@inject(store => {
    const { ifaceMngr, myShopApiStore } = store;
    return { ifaceMngr, myShopApiStore };
})

@observer
class AppBody extends React.Component {
    componentDidMount = async () => {
        const { setSearchValue } = this.props.myShopApiStore;
        setSearchValue('', true);
    
        const checkDuration = 6000;
        const intervalDelay = 1000;
        let elapsedTime = 0;
        let canRunCheckCoupon = await this.checkIfOrderCompleate();
    
        if (!canRunCheckCoupon) {
            return;
        }
    
        const intervalId = setInterval(async () => {
            elapsedTime += intervalDelay;
    
            canRunCheckCoupon = await this.checkIfOrderCompleate();
    
            if (!canRunCheckCoupon || elapsedTime >= checkDuration) {
                clearInterval(intervalId);
                if (canRunCheckCoupon) {
                    this.checkCoupon();
                }
            }
        }, intervalDelay);
    };

    checkIfOrderCompleate = async () => {
        const { creditUniqueId, checkPreorderResp, clearReadyToOrderCartItems, clearBlockClosing, clearCoupon, setCreditUniqueId } = this.props.myShopApiStore;
        let answer = 1;
        if(creditUniqueId) {
            const resp = await checkPreorderResp();
            if(resp) {
                switch(resp.data.errorCode) {
                    case '0':
                        setCreditUniqueId('');
                        clearReadyToOrderCartItems();
                        clearBlockClosing();
                        clearCoupon();
                        this.coupons = [];
                        answer = 0;
                        break;
                    default:
                        break;
                }
            }
        }
        return answer;
    };

    checkCoupon = async () => {
        const { checkCouponResp, shop, getCoupon, clearCoupon, calcTotalCartPrice } = this.props.myShopApiStore;
        const coupon = shop.withCoupons ? await getCoupon() : null;
        if(!coupon) return;
        
        const resp = await checkCouponResp(coupon?.code);
        if(resp.errorCode !== '0') {
            clearCoupon();
            calcTotalCartPrice();
        }
    }

    render() {
        const { activeTopMenu } = this.props.ifaceMngr;
        const abouts = ["About", "Terms", "PPolicy", "RetPolicy", "Accessibility"]; 
        return (
        <div className={style.container}>
            <div className={style.AppBody}>
                {
                    (activeTopMenu === "Payment") ? (
                        <Payment openBasket={this.props.openBasket} />
                    ) : ''
                }
                {
                    (activeTopMenu === "ShopMenu") ? (
                        <ShopMenu basketIsOpen={this.props.basketIsOpen} closeBasket={this.props.closeBasket} fastToggleBasket={this.props.fastToggleBasket} toggleBasket={this.props.toggleBasket}/>
                    ) : ''
                }
                {
                    (activeTopMenu === "History") ? (
                        <History />
                    ) : ''
                }
                {
                    (activeTopMenu === "MyBudget") ? (
                        <MyBudget />
                    ) : ''
                }
                {
                    (abouts.includes(activeTopMenu)) ? (
                        <About />
                    ) : ''
                }
                {
                    (activeTopMenu === "Contacts") ? (
                        <Contacts />
                    ) : ''
                }
                {
                    (activeTopMenu === "Registration") ? (
                        <Registration />
                    ) : ''
                }
            </div>
        </div>
        )

    }
}

export default AppBody;